import React from "react";

const Button = ({ styles }) => {
  return (
    <button
      type="button"
      className={`py-4 px-6 shadow-lg bg-blue-gradient 
      font-poppins
    font-medium text-[18px] text-primary outline-none
    ${styles}
    transform transition  hover:translate-y-0.5
      rounded-[10px]
    `}>
      Get Started
    </button>
  );
};

export default Button;
